import { useEffect } from 'react'
import { useStateMachine } from 'little-state-machine'
import { normalize } from 'normalizr'
import Prismic from 'prismic-javascript'
import moment from 'moment'
import { client } from 'utils/prismic'
import { reducer } from 'utils/redux'
import { singlePage } from 'utils/normalizr'

const queryHomePage = () => client.query([
  Prismic.Predicates.at('document.type', 'homepage')
])

export const useFetchHomePage = () => {
  const { state, action } = useStateMachine(reducer)
  const { singlePagesMeta } = state ?? {}

  useEffect(() => {
    const { fetchedDate, status } = singlePagesMeta?.fetchHomePage ?? {}
    if ((!fetchedDate || moment().diff(fetchedDate, 's') > 30) && status !== 'fetching') {
      action({
        singlePagesMeta: {
          fetchHomePage: {
            status: 'fetching'
          }
        }
      })

      queryHomePage()
        .then((res) => {
          const { results } = res
          if (results) {
            const { entities } = normalize(results, [singlePage])

            const payload = {
              singlePages: entities.singlePages,
              singlePagesMeta: {
                fetchHomePage: {
                  fetchedDate: moment().format(),
                  status: 'success',
                  error: null
                }
              }
            }
            action(payload)
          }
        })
        .catch((error) => {
          const payload = {
            singlePagesMeta: {
              fetchHomePage: {
                fetchedDate: moment().format(),
                status: 'error',
                error
              }
            }
          }
          action(payload)
        })
      }
    }, [action, singlePagesMeta])

  return {
    state
  }
}
