import React from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
// import { themeGet } from '@styled-system/theme-get'
import SearchBar from 'components/SearchBar/SearchBar'

const NavbarSearchWrapper = styled.div`
  flex: 1 1 auto;
  margin: 0 24px;
`

const NavbarSearch = (props) => {
  return (
    <NavbarSearchWrapper className="navbar_search">
      <SearchBar />
    </NavbarSearchWrapper>
  );
};

export default withRouter(NavbarSearch);
