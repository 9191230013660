import React, { useCallback, useMemo, useState } from 'react'
import { RichText, Date as ParseDate } from 'prismic-reactjs'
import { useStateMachine } from 'little-state-machine'
import { Link as PrismicLink } from 'prismic-reactjs'
import cx from 'classnames'
import moment from 'moment'
import MdClose from '@meronex/icons/md/MdClose'
import { Button } from 'antd'
import Container from 'components/UI/Container/Container'
import Link from 'components/Link/Link'
import {
  DefaultWrapper,
  AnnouncementHeader,
  CloseBtnWrapper,
  CallToActionWrapper
} from './GlobalAnnouncement.style'
import { useFetchHomePage } from 'hooks/useFetchHomePage'
import { linkResolver } from 'utils/prismic'
import { reducer } from 'utils/redux'

const HalfGhostButton = ({ className, ...props }) => {
  const [isHover, setIsHover] = useState(false)
  const [isActive, setIsActive] = useState(false)

  const handleMouseEnter = useCallback(() => setIsHover(true), [setIsHover])
  const handleMouseLeave = useCallback(() => setIsHover(false), [setIsHover])
  const handleFocus = useCallback(() => setIsActive(true), [setIsActive])
  const handleBlur = useCallback(() => setIsActive(false), [setIsActive])

  return (
    <Button
      className={cx(className, { 'ant-btn-background-ghost': (!isHover && !isActive) })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      {...props} />
  )
}

const CallToActionSlice = ({ slice }) => {
  const type = slice?.slice_type
  if (!type || type !== 'cta_button') return null

  const { items } = slice
  const Buttons = items.map(({ text, style, link }, index) => {
    if (!text || !link) return null

    return (
      <Link key={`cta-${index}`} className='cta-link' to={PrismicLink.url(link, linkResolver)} target={link?.target}>
        <HalfGhostButton>
          {text}
        </HalfGhostButton>
      </Link>
    )
  })

  return (
    <CallToActionWrapper>
      {Buttons}
    </CallToActionWrapper>
  )
}

const SliceComponents = {
  'cta_button': CallToActionSlice
}

const GlobalAccouncement = () => {
  const { state } = useFetchHomePage()

  const {
    global_announcement_show,
    global_announcement_dismissible,
    global_announcement_updated_date,
    global_announcement_text,
    body1
  } = state?.singlePages?.homepage?.data ?? {}

  const dismissedDate = state?.persist?.globalAnnouncementDismissedDate

  const shouldShowAnnouncement = useMemo(() => {
    // If we set the show field in Prismic to false, don't show it
    if (!global_announcement_show) return false

    // If the announcement is not dismissible at all, show it
    if (!global_announcement_dismissible) return true

    // If the user never dismissed the announcement, then show it
    if (!dismissedDate) return true

    // If we somehow didn't set the updated date in Prismic, don't show it
    if (!global_announcement_updated_date) return false

    // If we set the updated date to a future date, don't show it
    if (moment(ParseDate(global_announcement_updated_date)) > moment()) return false

    // Determine if our announcement update happened after the user's dismissed date
    return moment(ParseDate(global_announcement_updated_date)) > moment(dismissedDate)
  }, [global_announcement_show, global_announcement_dismissible, global_announcement_updated_date, dismissedDate])

  const { action } = useStateMachine(reducer)
  const dismissGlobalAnnouncement = useCallback(() => {
    action({
      persist: {
        globalAnnouncementDismissedDate: moment().format()
      }
    })
  }, [action])

  const Slices = body1?.map((slice, index) => {
    const { slice_type } = slice ?? {}
    const SliceComponent = SliceComponents[slice_type]

    if (SliceComponent) return (<SliceComponent key={`slice-${index}`} slice={slice} />)

    return null
  })

  return (shouldShowAnnouncement) ? (
    <>
      <DefaultWrapper showHeader={global_announcement_dismissible}>
        <Container fluid>
          {(global_announcement_dismissible) ? (
            <AnnouncementHeader>
                <CloseBtnWrapper onClick={dismissGlobalAnnouncement}>
                  <MdClose />
                </CloseBtnWrapper>
            </AnnouncementHeader>
          ) : null}
          {(global_announcement_text) ? (
            <RichText render={global_announcement_text} linkResolver={linkResolver} />
          ) : null}
          {Slices}
        </Container>
      </DefaultWrapper>
    </>
  ) : null
}

export default GlobalAccouncement
