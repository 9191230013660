import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import Layout from './container/Layout/Layout';
import {
  HOME_PAGE,
  INFO_PAGE,
  DESTINATIONS_PAGE,
  TRIPS_PAGE,
  LEGAL_PAGE
} from './settings/constant';

/**
 *
 * Public Routes
 *
 */
const Loading = () => null;

const routes = [
  {
    path: HOME_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Home" */ './container/Home/Home'),
      loading: Loading,
      modules: ['Home'],
    }),
    exact: true,
  },
  // {
  //   path: LOGIN_PAGE,
  //   component: Loadable({
  //     loader: () =>
  //       import(
  //         /* webpackChunkName: "SignIn" */ './container/Auth/SignIn/SignIn'
  //       ),
  //     loading: Loading,
  //     modules: ['SignIn'],
  //   }),
  // },
  // {
  //   path: REGISTRATION_PAGE,
  //   component: Loadable({
  //     loader: () =>
  //       import(
  //         /* webpackChunkName: "SignUp" */ './container/Auth/SignUp/SignUp'
  //       ),
  //     loading: Loading,
  //     modules: ['SignUp'],
  //   }),
  // },
  // {
  //   path: FORGET_PASSWORD_PAGE,
  //   component: Loadable({
  //     loader: () =>
  //       import(
  //         /* webpackChunkName: "ForgetPassword" */ './container/Auth/ForgetPassword'
  //       ),
  //     loading: Loading,
  //     modules: ['ForgetPassword'],
  //   }),
  // },
  // {
  //   path: `${SINGLE_POST_PAGE}/:slug`,
  //   component: Loadable({
  //     loader: () =>
  //       import(
  //         /* webpackChunkName: "SinglePageView" */ './container/SinglePage/SinglePageView'
  //       ),
  //     loading: Loading,
  //     modules: ['SinglePageView'],
  //   }),
  // },
  // {
  //   path: LISTING_POSTS_PAGE,
  //   component: Loadable({
  //     loader: () =>
  //       import(/* webpackChunkName: "Listing" */ './container/Listing/Listing'),
  //     loading: Loading,
  //     modules: ['Listing'],
  //   }),
  // },
  // {
  //   path: AGENT_PROFILE_PAGE,
  //   component: Loadable({
  //     loader: () =>
  //       import(
  //         /* webpackChunkName: "AgentDetailsViewPage" */ './container/Agent/AccountDetails/AgentDetailsViewPage'
  //       ),
  //     loading: Loading,
  //     modules: ['AgentDetailsViewPage'],
  //   }),
  // },
  // {
  //   path: PRIVACY_PAGE,
  //   component: Loadable({
  //     loader: () =>
  //       import(/* webpackChunkName: "privacy" */ './container/Privacy/Privacy'),
  //     loading: Loading,
  //     modules: ['Privacy'],
  //   }),
  // },
  // {
  //   path: PRICING_PLAN_PAGE,
  //   component: Loadable({
  //     loader: () =>
  //       import(/* webpackChunkName: "Pricing" */ './container/Pricing/Pricing'),
  //     loading: Loading,
  //     modules: ['Pricing'],
  //   }),
  // },
  {
    path: `${DESTINATIONS_PAGE}`,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "Destinations" */ './container/Destinations/Destinations'
        ),
      loading: Loading,
      modules: ['Destinations'],
    }),
    exact: true
  },
  {
    path: `${DESTINATIONS_PAGE}/:slug`,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "Destination" */ './container/Destination/Destination'
        ),
      loading: Loading,
      modules: ['Destination'],
    })
  },
  {
    path: `${TRIPS_PAGE}`,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "Trips" */ './container/Trips/Trips'
        ),
      loading: Loading,
      modules: ['Trips'],
    }),
    exact: true
  },
  {
    path: `${LEGAL_PAGE}/:slug`,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Legal" */ './container/Legal/Legal'),
      loading: Loading,
      modules: ['Legal'],
    })
  },
  {
    path: `${INFO_PAGE}/:slug`,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Info" */ './container/Info/Info'),
      loading: Loading,
      modules: ['Info'],
    })
  },
];

/**
 *
 * Protected Route Component
 *
 */
// const AddListing = Loadable({
//   loader: () =>
//     import(
//       /* webpackChunkName: "AddListing" */ './container/AddListing/AddListing'
//     ),
//   loading: Loading,
//   modules: ['AddListing'],
// });

// const AgentAccountSettingsPage = Loadable({
//   loader: () =>
//     import(
//       /* webpackChunkName: "AgentAccountSettingsPage" */ './container/Agent/AccountSettings/AgentAccountSettingsPage'
//     ),
//   loading: Loading,
//   modules: ['AgentAccountSettingsPage'],
// });

/**
 *
 * Not Found Route Component
 *
 */

const NotFound = Loadable({
  loader: () =>
    import(/* webpackChunkName: "NotFound" */ './container/404/404'),
  loading: Loading,
  modules: ['NotFound'],
});

// const ProtectedRoute = ({ component: Component, ...rest }) => {
//   const { loggedIn } = useContext(AuthContext);
//   return (
//     <Route
//       render={(props) =>
//         loggedIn ? <Component {...props} /> : <Redirect to={LOGIN_PAGE} />
//       }
//       {...rest}
//     />
//   );
// };

/**
 *
 * Overall Router Component
 *
 */

const Routes = () => {
  return (
    <Layout>
      <Switch>
        {routes.map(({ path, component, exact = false }) => (
          <Route key={path} path={path} exact={exact} component={component} />
        ))}
        {/* <ProtectedRoute path={ADD_HOTEL_PAGE} component={AddListing} /> */}
        {/* <ProtectedRoute
          path={AGENT_ACCOUNT_SETTINGS_PAGE}
          component={AgentAccountSettingsPage}
        /> */}
        <Route component={NotFound} />
      </Switch>
    </Layout>
  );
};

export default Routes;
