import React from 'react'
import PropTypes from 'prop-types'
import { components } from 'react-select'
import BiSearch from '@meronex/icons/bi/BiSearch';

const ValueContainer = ({ children, ...props }) => (
  <>
    <div className='searchbar__prefix'>
      <BiSearch />
    </div>
    <components.ValueContainer {...props}>
      {children}
    </components.ValueContainer>
  </>
)

ValueContainer.propTypes = {
  children: PropTypes.node
}

export default ValueContainer
