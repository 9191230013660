import React from 'react';
import PropTypes from 'prop-types';
import Container from 'components/UI/Container/Container';
import EmailNewsletter from '../EmailNewsletter'
import { useLocation } from 'library/hooks/useLocation';
import FooterWrapper, {
  LogoWrapper,
  MenuWrapper,
  CopyrightArea,
  SecondaryFooter,
} from './Footer.style';

const Footer = ({ logo, menu, bgSrc, copyright, className, path }) => {
  const { pathname = '#' } = useLocation()

  return (
    <>
      <FooterWrapper id="footer" className={className} bg-img={bgSrc}>
        <Container fluid>
          <EmailNewsletter key={`email-subscribe-${pathname}`}/>
        </Container>
        {logo && <LogoWrapper>{logo}</LogoWrapper>}
        {menu && <MenuWrapper>{menu}</MenuWrapper>}
        {copyright && <CopyrightArea>{copyright}</CopyrightArea>}
      </FooterWrapper>
      {!!path && <SecondaryFooter />}
    </>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  logo: PropTypes.element,
  menu: PropTypes.element,
  bgSrc: PropTypes.string,
  copyright: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default Footer;
