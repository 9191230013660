import React, { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { Link as PrismicLink } from 'prismic-reactjs'
import { Menu } from 'antd'
import Link from 'components/Link/Link'
import { useFetchHomePage } from 'hooks/useFetchHomePage'
import { linkResolver } from 'utils/prismic'
import { DESTINATIONS_PAGE, TRIPS_PAGE } from 'settings/constant'

const MainMenu = ({ className }) => {
  const { state } = useFetchHomePage()
  const { header_menu } = state?.singlePages?.homepage?.data ?? {}

  const NavItems = useMemo(() => {
    if (!header_menu) return null

    return header_menu?.map(({ menu_text, link }, index) => {
      if (!menu_text || !link) return null

      return (
        <Menu.Item key={`prismic-${index}`}>
          <Link to={PrismicLink.url(link, linkResolver)} nav>{menu_text}</Link>
        </Menu.Item>
      )
    })
  }, [header_menu])

  return (
    <Menu className={className}>
      <Menu.Item key="default-0">
        <NavLink exact to={`${DESTINATIONS_PAGE}`}>Destinations</NavLink>
      </Menu.Item>
      <Menu.Item key="default-1">
        <NavLink to={`${TRIPS_PAGE}`}>Trips</NavLink>
      </Menu.Item>
      {NavItems}
    </Menu>
  )
}

export default MainMenu
