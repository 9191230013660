import React from 'react'
import GlobalStyle from './Global.style'
import FontStyle from './Font.style'

export default () => (
  <>
    <GlobalStyle />
    <FontStyle />
  </>
)
