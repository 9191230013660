import React from 'react'
import { Link as RouterLink, NavLink } from 'react-router-dom'

const Link = ({ to, children, target, nav, ...props }) => {
  const isExternal = /^https?:\/\//.test(to)

  const linkProps = {
    ...props,
    ...(target ? { target, rel: 'noopener' } : {})
  }

  if (isExternal) {
    return (<a href={to} {...linkProps}>{children}</a>)
  }

  return (nav) ? (
    <NavLink to={to} children={children} {...linkProps} />
  ) : (
    <RouterLink to={to} children={children} {...linkProps} />
  )
}

export default Link
