import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const useScrollManagement = () => {
  const [ locationKeys, setLocationKeys ] = useState([])
  const history = useHistory()

  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'PUSH') {
        setLocationKeys([ location.key ])

        // Scroll to top when the user actively clicks a link
        window.scrollTo(0, 0)
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([ _, ...keys ]) => keys)

          // Handle forward event

        } else {
          setLocationKeys((keys) => [ location.key, ...keys ])

          // Handle back event

        }
      }
    })
  }, [locationKeys, history])
}

export default useScrollManagement
