import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga'

const history = createBrowserHistory()
history.listen((location) => {
  const { pathname } = location ?? {}

  if (pathname) {
    ReactGA.pageview(pathname)
  }
})

export default history;
