import { useEffect } from 'react'
import { useStateMachine } from 'little-state-machine'
import { normalize } from 'normalizr'
import Prismic from 'prismic-javascript'
import { RichText } from 'prismic-reactjs'
import moment from 'moment'
import forOwn from 'lodash/forOwn'
import { client } from 'utils/prismic'
import { reducer } from 'utils/redux'
import { destination } from 'utils/normalizr'

const tripsField = (process.env.REACT_APP_PRISMIC_ENV !== 'production') ? 'my.destination_page.trips_staging' : 'my.destination_page.trips'

// WARNING: Prismic only lets you query up to 100 documents at a time.
// If we were to create more than 100 destinations page, we will no longer be
// able to pre-fetch all titles for autocomplete feature to use
const queryAllDestinationTitles = () => client.query([
  Prismic.Predicates.at('document.type', 'destination_page')
], {
  fetch: ['document.uid', 'my.destination_page.title', tripsField],
  pageSize: 100
})

export const useFetchAllDestinationTitles = () => {
  const { state, action } = useStateMachine(reducer)
  const { destinationsMeta } = state ?? {}

  useEffect(() => {
    const { fetchedDate, status } = destinationsMeta?.fetchAllDestinationTitles ?? {}
    if ((!fetchedDate || moment().diff(fetchedDate, 's') > 30) && status !== 'fetching') {
      action({
        destinationsMeta: {
          fetchAllDestinationTitles: {
            status: 'fetching'
          }
        }
      })

      queryAllDestinationTitles()
        .then((res) => {
          const { results } = res
          if (results) {
            const { entities } = normalize(results, [destination])

            const uidMap = {}
            forOwn(entities.destinations, (entity) => {
              const uid = entity.uid
              if (uid) {
                uidMap[uid] = entity.id
              }
            })

            // Create options array for react-select
            const selectOptions = results.map(({ uid, data }) => {
              return {
                label: RichText.asText(data.title),
                value: uid
              }
            })

            const payload = {
              destinations: entities.destinations,
              destinationsMeta: {
                fetchAllDestinationTitles: {
                  fetchedDate: moment().format(),
                  status: 'success',
                  error: null
                },
                uidMap,
                selectOptions
              }
            }
            action(payload)
          }
        })
        .catch((error) => {
          const payload = {
            destinationsMeta: {
              fetchAllDestinationTitles: {
                fetchedDate: moment().format(),
                status: 'error',
                error
              }
            }
          }
          action(payload)
        })
      }
    }, [action, destinationsMeta])

  return {
    state
  }
}
