import React, { useState, useMemo, useCallback } from 'react'
import { Button, Input, Modal } from 'antd';
import Heading from 'components/UI/Heading/Heading'
import { addSubscriberToNewsletter } from 'services/hipherd'
import NewsletterWrapper, { NewsletterModalWrapper, ErrorMessageWrapper } from './EmailNewsletter.style'

import MailSentImage from 'assets/images/illustrations/mail-sent.svg'

const EmailNewsletter = () => {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [isModalShowing, setIsModalShowing] = useState(false)
  const [error, setError] = useState('')

  const handleModalCancel = useCallback(() => {
    setIsModalShowing(false)
  }, [])

  const handleEmailChange = useCallback((evt) => {
    const value = evt.target.value
    setEmail(value)
    setError('')
  }, [])

  const handleEmailSubmit = useCallback((evt) => {
    evt.preventDefault()

    const updatedEmail = email.trim()

    if (updatedEmail) {
      setLoading(true)
      addSubscriberToNewsletter(updatedEmail)
        .then(() => {
          setIsModalShowing(true)
          setLoading(false)
          setEmail('')
        })
        .catch((err) => {
          setLoading(false)
          setError('We could not add that email, please try again later.')
        })
    } else {
      setError('Please provide a valid email address.')
    }
  }, [email, setLoading, setIsModalShowing])

  const ErrorMessage = useMemo(() => {
    return (error) ? (
      <ErrorMessageWrapper>{error}</ErrorMessageWrapper>
    ) : null
  }, [error])

  return (
    <NewsletterWrapper>
      <Heading as="h3" mb="0" fontSize="24px" content="Subscribe for updates." />
      <form className="newsletter_form" onSubmit={handleEmailSubmit}>
        <Input
          type="email"
          name="email"
          value={email}
          placeholder="Your email address"
          aria-label="Your email address"
          size="large"
          onChange={handleEmailChange} />
        <Button
          className='newsletter_btn'
          type="primary"
          htmlType="submit"
          size="large"
          loading={loading}
          onSubmit={handleEmailSubmit}>
          Subscribe
        </Button>
        {ErrorMessage}
      </form>
      <Modal
        visible={isModalShowing}
        onCancel={handleModalCancel}
        footer={null}
        wrapClassName="newsletter_modal"
        closable>
        <NewsletterModalWrapper>
          <img src={MailSentImage} alt="mail-sent" />
          <Heading as="h3" mb="0" mt="30px" content="Thanks for subscribing! We'll keep you in the loop with any major updates." />
        </NewsletterModalWrapper>
      </Modal>
    </NewsletterWrapper>
  )
}

export default EmailNewsletter
