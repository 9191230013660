import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const DefaultWrapper = styled.div`
  text-align: center;
  color: ${themeGet('color.1', '#ffffff')};
  background-color: ${themeGet('primary.1', 'rgba(89, 92, 255, 0.9)')};
  padding-top: ${({ showHeader }) => showHeader ? '0px' : '14px'};
  padding-bottom: ${({ showHeader }) => showHeader ? '46px' : '4px'};

  h1, h2, h3, h4, h5, h6 {
    color: ${themeGet('color.1', '#ffffff')};
    font-weight: 700;
    margin-bottom: 12px;
  }

  p {
    color: ${themeGet('color.1', '#ffffff')};
    font-size: 15px;
    margin-bottom: 12px;
  }

  a {
    color: ${themeGet('color.1', '#ffffff')};
    text-decoration: underline;
  }
`

export const AnnouncementHeader = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
`

export const CloseBtnWrapper = styled.span`
  font-size: 24px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

export const CallToActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 24px 0px 12px;

  .cta-link {
    margin: 0 12px;
  }

  .ant-btn{
    font-weight: 600;

    &:hover {
      color: ${themeGet('primary.0', '#595CFF')};
      border-color: ${themeGet('primary.0', '#595CFF')};
    }

    &:focus {
      color: #F200D9;
      border-color: #F200D9;
    }
  }
`
