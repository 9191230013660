import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import matchSorter from 'match-sorter'
import AsyncSelect from 'react-select/async'
import { withTheme } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import ValueContainer from './ValueContainer'
import Option from './Option'
import { useFetchAllDestinationTitles } from 'hooks/useFetchAllDestinationTitles'
import { DESTINATIONS_PAGE } from 'settings/constant'
import SearchBarWrapper from './SearchBar.style'

const components = {
  DropdownIndicator: null,
  ValueContainer,
  Option
}

const mapThemeColors = (props) => {
  const primaryColor = themeGet('primary.0', '#595CFF')(props)

  return {
    primary: primaryColor,
    primary75: `${primaryColor}a0`,
    primary50: `${primaryColor}80`,
    primary25: `${primaryColor}40`
  }
}

const SearchBar = ({ theme }) => {
  const { state } = useFetchAllDestinationTitles()
  const [, setKeywords] = useState('')
  const history = useHistory()

  const selectOptions = state?.destinationsMeta?.selectOptions ?? []

  const loadOptions = useCallback((inputValue, callback) => {
    callback(matchSorter(selectOptions, inputValue, { keys: ['label'] }))
  }, [selectOptions])

  const handleInputChange = useCallback((value) => setKeywords(value), [setKeywords])

  const handleSelectDestination = useCallback(({ value }) => {
    history.push(`${DESTINATIONS_PAGE}/${value}`)
  }, [history])

  const isLoading = (state?.destinationsMeta?.fetchAllDestinationTitles?.status === 'fetching')
  const searchTheme = useCallback((reactSelectTheme) => ({
    ...reactSelectTheme,
    colors: {
      ...reactSelectTheme?.colors,
      ...mapThemeColors({ theme })
    }
  }), [theme])

  return (
    <SearchBarWrapper>
      <AsyncSelect
        options={selectOptions}
        classNamePrefix='searchbar'
        loadOptions={loadOptions}
        noOptionsMessage={() => null}
        controlShouldRenderValue={false}
        onInputChange={handleInputChange}
        onChange={handleSelectDestination}
        isLoading={isLoading}
        components={components}
        theme={searchTheme}
        placeholder='Search for a U.S state...' />
    </SearchBarWrapper>
  )
}

export default withTheme(SearchBar)
