import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const NewsletterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${'' /* background-color: ${themeGet('color.2', '#F7F7F7')}; */}
  background: linear-gradient(180deg, #82FFA6 0%, #00E3DE 100%);
  border-radius: 4px;
  padding: 50px 40px;

  @media only screen and (max-width: 991px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 667px) {
    padding: 40px 30px;
  }

  .newsletter_form {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1;
    margin-left: 60px;

    input {
      max-width: 400px;
      width: 100%;
      border-color: transparent;
    }

    @media only screen and (max-width: 991px) {
      width: 100%;
      margin-top: 20px;
      margin-left: 0;

      input {
        max-width: 100%;
      }
    }
  }

  /* submit button style */
  .newsletter_btn {
    min-width: 150px;
    height: 60px;
    color: ${themeGet('color.1', '#ffffff')};
    background-color: ${themeGet('primary.0', '#595CFF')};
    border-color: ${themeGet('primary.0', '#595CFF')};
    border-radius: 0 3px 3px 0;
    border: 0;
    box-shadow: none;
    font-size: 15px;
    font-weight: 700;
    text-shadow: none;
    margin-left: -1px;

    &::after {
      display: none;
    }

    &:hover {
      opacity: 0.85;
    }

    @media only screen and (max-width: 667px) {
      min-width: auto;
      height: 52px;
    }
  }
`;

export const NewsletterModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;

  img {
    max-width: 180px;
  }
`

export const ErrorMessageWrapper = styled.span`
  color: ${themeGet('error.0', '#FF4733')};
  position: absolute;
  right: 0;
  bottom: -30px;
`

export default NewsletterWrapper
