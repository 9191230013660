import mergeWith from 'lodash/mergeWith'
import isArray from 'lodash/isArray'

export const reducer = (state, payload) => {
  return mergeWith({}, state, payload, (objValue, srcValue) => {
    if (isArray(objValue)) {
      return srcValue
    }
  })
}
