import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { StateMachineProvider, createStore, setStorageType } from 'little-state-machine';
import ReactGA from 'react-ga'
import history from 'library/helpers/history'
import theme from 'themes/default.theme';
import GlobalStyles from 'assets/style';
import AuthProvider from 'context/AuthProvider';
import Routes from './router';

const storeName = 'FleeCOVID Data Store'

setStorageType(window.localStorage)

createStore({}, {
  name: storeName,
  syncStores: {
    externalStoreName: storeName,
    transform: ({ externalStoreData, currentStoreData }) => {
      return {
        persist: {
          ...(externalStoreData?.persist ?? {}),
          ...(currentStoreData?.persist ?? {})
        }
      }
    }
  }
})

const App = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <StateMachineProvider>
        <GlobalStyles />
        <Router history={history}>
          <AuthProvider>
            <Routes />
          </AuthProvider>
        </Router>
      </StateMachineProvider>
    </ThemeProvider>
  )
};

const USE_STAGING = process.env.REACT_APP_PRISMIC_ENV !== 'production'
const GA_TRACKING_ID = (USE_STAGING) ? 'UA-114360174-7' : 'UA-114360174-8'

ReactGA.initialize(GA_TRACKING_ID)

ReactDOM.render(<App />, document.getElementById('root'));
