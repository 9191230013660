import Prismic from 'prismic-javascript'
import { DESTINATIONS_PAGE, LEGAL_PAGE, INFO_PAGE } from 'settings/constant'

const apiEndpoint = `https://${process.env.REACT_APP_PRISMIC_REPO}.prismic.io/api/v2`
const accessToken = process.env.REACT_APP_PRISMIC_API_KEY

export const client = Prismic.client(apiEndpoint, { accessToken })

/**
 * Resolves links in rich-text/link into correct paths
 * @param {Object} doc The Prismic doc
 */
export const linkResolver = (doc) => {
  // URL for destination_page type
  if (doc.type === 'destination_page') {
    return `${DESTINATIONS_PAGE}/${doc.uid}`
  }

  // URL for legal_page type
  if (doc.type === 'legal_page') {
    return `${LEGAL_PAGE}/${doc.uid}`
  }

  if (doc.type === 'info_page') {
    return `${INFO_PAGE}/${doc.uid}`
  }

  // Backup for all other types
  return '/'
}

/**
 * Extract paragraphs from rich text
 * @param {Array} richText The Prismic rich text data
 * @param {Number} num The number of paragraphs to extract
 */
export const extractParagraphs = (richText = [], num = 5) => {
  const paragraphs = []
  for (const element of richText) {
    if (element.type === 'paragraph') {
      paragraphs.push(element)
    }

    if (paragraphs.length >= num) break
  }

  return paragraphs
}
