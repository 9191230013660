import React, { useMemo } from 'react'
import { Link as PrismicLink } from 'prismic-reactjs'
import { Menu } from 'antd'
import Link from 'components/Link/Link'
import { useFetchHomePage } from 'hooks/useFetchHomePage'
import { linkResolver } from 'utils/prismic'

const FooterMenu = () => {
  const { state } = useFetchHomePage()
  const { footer_menu } = state?.singlePages?.homepage?.data ?? {}

  const NavItems = useMemo(() => {
    if (!footer_menu) return null

    return footer_menu?.map(({ menu_text, link }, index) => {
      if (!menu_text || !link) return null

      return (
        <Menu.Item key={`prismic-${index}`}>
          <Link to={PrismicLink.url(link, linkResolver)} nav>{menu_text}</Link>
        </Menu.Item>
      )
    })
  }, [footer_menu])

  return (
    <Menu>
      {NavItems}
    </Menu>
  )
}

export default FooterMenu
