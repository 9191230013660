import { createGlobalStyle } from 'styled-components'

const fontWeightScale = (name) => {
  return `
    .font-weight-${name} {
      font-weight: ${name};
    }
  `
}

const FontStyle = createGlobalStyle`
  ${fontWeightScale('normal')}
  ${fontWeightScale('bold')}
  ${fontWeightScale('bolder')}
  ${fontWeightScale('lighter')}
  ${fontWeightScale('100')}
  ${fontWeightScale('200')}
  ${fontWeightScale('300')}
  ${fontWeightScale('400')}
  ${fontWeightScale('500')}
  ${fontWeightScale('600')}
  ${fontWeightScale('700')}
  ${fontWeightScale('800')}
  ${fontWeightScale('900')}
  ${fontWeightScale('initial')}
  ${fontWeightScale('inherit')}
`

export default FontStyle
