import axios from 'axios'

const bulkGetPublicBoardsURL = `/api/v1/boards/public/bulk`
const subscribeNewsletterURL = `/api/v1/subscribe/newsletters/fleecovid`

/**
 * POST public board ids to bulk-get public boards
 * @param {String} sharedBoardIds The array of shared board ids to fetch
 */
export const bulkGetPublicBoards = (sharedBoardIds) => {
  return axios.post(bulkGetPublicBoardsURL, sharedBoardIds)
    .then((res) => {
      const { data } = res
      return data
    })
}

export const addSubscriberToNewsletter = (email) => {
  return axios.post(subscribeNewsletterURL, { email })
    .then((res) => {
      const { data } = res
      return data
    })
}
