import React from 'react'
import { components } from 'react-select'
import { Avatar } from 'antd'
import RiMapPin2Fill from '@meronex/icons/ri/RiMapPin2Fill'
import ALABAMA_ICON from 'assets/images/states/alabama.svg'
import ALASKA_ICON from 'assets/images/states/alaska.svg'
import ARIZONA_ICON from 'assets/images/states/arizona.svg'
import ARKANSAS_ICON from 'assets/images/states/arkansas.svg'
import CALIFORNIA_ICON from 'assets/images/states/california.svg'
import COLORADO_ICON from 'assets/images/states/colorado.svg'
import CONNECTICUT_ICON from 'assets/images/states/connecticut.svg'
import DELAWARE_ICON from 'assets/images/states/delaware.svg'
import FLORIDA_ICON from 'assets/images/states/florida.svg'
import GEORGIA_ICON from 'assets/images/states/georgia.svg'
import HAWAII_ICON from 'assets/images/states/hawaii.svg'
import IDAHO_ICON from 'assets/images/states/idaho.svg'
import ILLINOIS_ICON from 'assets/images/states/illinois.svg'
import INDIANA_ICON from 'assets/images/states/indiana.svg'
import IOWA_ICON from 'assets/images/states/iowa.svg'
import KANSAS_ICON from 'assets/images/states/kansas.svg'
import KENTUCKY_ICON from 'assets/images/states/kentucky.svg'
import LOUISIANA_ICON from 'assets/images/states/louisiana.svg'
import MAINE_ICON from 'assets/images/states/maine.svg'
import MARYLAND_ICON from 'assets/images/states/maryland.svg'
import MASSACHUSETTS_ICON from 'assets/images/states/massachusetts.svg'
import MICHIGAN_ICON from 'assets/images/states/michigan.svg'
import MINNESOTA_ICON from 'assets/images/states/minnesota.svg'
import MISSISSIPPI_ICON from 'assets/images/states/mississippi.svg'
import MISSOURI_ICON from 'assets/images/states/missouri.svg'
import MONTANA_ICON from 'assets/images/states/montana.svg'
import NEBRASKA_ICON from 'assets/images/states/nebraska.svg'
import NEVADA_ICON from 'assets/images/states/nevada.svg'
import NEW_HAMPSHIRE_ICON from 'assets/images/states/new_hampshire.svg'
import NEW_JERSEY_ICON from 'assets/images/states/new_jersey.svg'
import NEW_MEXICO_ICON from 'assets/images/states/new_mexico.svg'
import NEW_YORK_ICON from 'assets/images/states/new_york.svg'
import NORTH_CAROLINA_ICON from 'assets/images/states/north_carolina.svg'
import NORTH_DAKOTA_ICON from 'assets/images/states/north_dakota.svg'
import OHIO_ICON from 'assets/images/states/ohio.svg'
import OKLAHOMA_ICON from 'assets/images/states/oklahoma.svg'
import OREGON_ICON from 'assets/images/states/oregon.svg'
import PENNSYLVANIA_ICON from 'assets/images/states/pennsylvania.svg'
import RHODE_ISLAND_ICON from 'assets/images/states/rhode_island.svg'
import SOUTH_CAROLINA_ICON from 'assets/images/states/south_carolina.svg'
import SOUTH_DAKOTA_ICON from 'assets/images/states/south_dakota.svg'
import TENNESSEE_ICON from 'assets/images/states/tennessee.svg'
import TEXAS_ICON from 'assets/images/states/texas.svg'
import UTAH_ICON from 'assets/images/states/utah.svg'
import VERMONT_ICON from 'assets/images/states/vermont.svg'
import VIRGINIA_ICON from 'assets/images/states/virginia.svg'
import WASHINGTON_ICON from 'assets/images/states/washington.svg'
import WEST_VIRGINIA_ICON from 'assets/images/states/west_virginia.svg'
import WISCONSIN_ICON from 'assets/images/states/wisconsin.svg'
import WYOMING_ICON from 'assets/images/states/wyoming.svg'

const US_STATE_ICONS = {
  alabama: ALABAMA_ICON,
  alaska: ALASKA_ICON,
  arizona: ARIZONA_ICON,
  arkansas: ARKANSAS_ICON,
  california: CALIFORNIA_ICON,
  colorado: COLORADO_ICON,
  connecticut: CONNECTICUT_ICON,
  delaware: DELAWARE_ICON,
  florida: FLORIDA_ICON,
  georgia: GEORGIA_ICON,
  hawaii: HAWAII_ICON,
  idaho: IDAHO_ICON,
  illinois: ILLINOIS_ICON,
  indiana: INDIANA_ICON,
  iowa: IOWA_ICON,
  kansas: KANSAS_ICON,
  kentucky: KENTUCKY_ICON,
  louisiana: LOUISIANA_ICON,
  maine: MAINE_ICON,
  maryland: MARYLAND_ICON,
  massachusetts: MASSACHUSETTS_ICON,
  michigan: MICHIGAN_ICON,
  minnesota: MINNESOTA_ICON,
  mississippi: MISSISSIPPI_ICON,
  missouri: MISSOURI_ICON,
  montana: MONTANA_ICON,
  nebraska: NEBRASKA_ICON,
  nevada: NEVADA_ICON,
  new_hampshire: NEW_HAMPSHIRE_ICON,
  new_jersey: NEW_JERSEY_ICON,
  new_mexico: NEW_MEXICO_ICON,
  new_york: NEW_YORK_ICON,
  north_carolina: NORTH_CAROLINA_ICON,
  north_dakota: NORTH_DAKOTA_ICON,
  ohio: OHIO_ICON,
  oklahoma: OKLAHOMA_ICON,
  oregon: OREGON_ICON,
  pennsylvania: PENNSYLVANIA_ICON,
  rhode_island: RHODE_ISLAND_ICON,
  south_carolina: SOUTH_CAROLINA_ICON,
  south_dakota: SOUTH_DAKOTA_ICON,
  tennessee: TENNESSEE_ICON,
  texas: TEXAS_ICON,
  utah: UTAH_ICON,
  vermont: VERMONT_ICON,
  virginia: VIRGINIA_ICON,
  washington: WASHINGTON_ICON,
  west_virginia: WEST_VIRGINIA_ICON,
  wisconsin: WISCONSIN_ICON,
  wyoming: WYOMING_ICON
}

const iconWidth = 36
const marginRight = 8

const Option = (props) => {
  const src = US_STATE_ICONS[props.data.value]
  const icon = (<span><RiMapPin2Fill style={{ fontSize: 16, verticalAlign: 'middle', color: '#111' }} /></span>)

  return (
    <>
      <components.Option {...props}>
        <Avatar
          size={iconWidth}
          style={{ marginRight }}
          src={src}
          icon={icon}
          alt={props.data.label} />
        {props.data.label}
      </components.Option>
    </>
  )
}

export default Option
